html {
    /* scroll-behavior: smooth !important; */
}



.card-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

}



.card {

    max-width: 100%;
    flex: 1 1 calc(33.33% - 1rem);

    max-width: calc(33.33% - 1rem);

    background-color: #f0f0f0;
    padding: 1rem;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px !important;


}


.name-price-div {
    display: flex;

    justify-content: space-between;
    flex-wrap: wrap !important;
    align-items: center;
}

.card-img {
    width: 100% !important;
    padding: 0.5rem;

}

.craft-btn {
    background-color: #EF674E !important;
    color: white !important;
    padding: 0.8rem !important;
}

.buy-btn {
    font-weight: bold !important;
    padding: 0.8rem !important;
}

.emojibrandedbuybtns {
    background-color: orange !important;
    color: white !important;
}

.aibrandedbuybtns {
    background-color: #911834 !important;
    color: white !important;
}




.emoji-branded {
    background-color: orange;
    display: inline-block;
    margin: 1rem;
    padding: 0.8rem;
    border-radius: 50px;
    color: white;
    font-size: 2.5rem;

}

.AI-branded {
    background-color: #911834;
    display: inline-block;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 50px;
    color: white;
    font-size: 2.5rem;
}

.Tech-branded {

    background-color: #614EAE;
    display: inline-block;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 50px;
    color: white;
    font-size: 2.5rem;
}

.techbrandedbuybtns {
    background-color: #614EAE !important;
    color: white !important;
}


.card-actions {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;

}


.name-of-shirt,
.price-of-shirt {
    font-weight: bold !important;

    padding: 0.5rem;
    border-radius: 10px;
}

.emoji-shirt-name,
.emoji-shirt-price {
    color: orange;
    border: 1px solid orange;
}


.AI-shirt-name,
.AI-shirt-price {
    color: #911834;
    border: 1px solid #911834;
}


.Tech-shirt-name,
.Tech-shirt-price {
    color: #614EAE;
    border: 1px solid #614EAE;
}


.carou-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1.5;
}

@media screen and (max-width: 600px) {
    .card {
        /* flex-basis: calc(50% - 1rem);
        max-width: calc(50% - 1rem); */

        flex-basis: calc(100% - 1rem);
        max-width: calc(85% - 1rem);
    }

    .name-price-div {
        justify-content: space-evenly !important;

    }

    .main_of_all {
        min-height: 55vh !important;
    }

    .main_of_all_reversed {
        min-height: 100vh !important;
    }

    .name-of-shirt,
    .price-of-shirt {
        font-size: 0.9rem !important;
        padding: 0.3rem;
    }



    .craft-btn {
        font-size: 1rem !important;
    }

    .get-btn {
        font-size: 1rem !important;
    }

    .card-actions {

        flex-direction: row;
    }

    .craft-btn,
    .buy-btn {

        padding: 0.5rem !important;
        margin: 0.2rem !important;

    }

    .emoji-branded {

        font-size: 1.5rem;

    }

    .AI-branded {

        font-size: 1.5rem;
    }

    .Tech-branded {

        font-size: 1.5rem;
    }






}