@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');
@import url('https://rsms.me/inter/inter.css');

html {
    font-family: 'Inter', sans-serif;
    overflow-y: scroll !important;

}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

* {
    box-sizing: border-box;
    /* overflow-y: hidden; */
    overflow-x: hidden;
}

html,
body,
#root,
#main {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    user-select: none;

    /* width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    user-select: none; */




}

body {
    background-color: #DAD9E0;
    font-family: 'Inter', sans-serif;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

canvas {
    opacity: 0;
    touch-action: none;
    animation: fade-in 1s ease 0.3s forwards;

}

header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 40px;
    align-items: center;
    position: fixed;
}

header p {
    font-weight: bold;
    font-size: 0.8rem;
    transition: all 1.2s ease-out;
}

header p.hidden {
    opacity: 0;
}

.section-main {

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    left: -15rem;

}

.section--container {
    margin-top: 5vh;
    margin-left: 5vw;
}

.section--container.hidden {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.section--container h1 {
    font-weight: 900;
    font-size: 13rem;
    letter-spacing: -6px;
    width: 100%;
    line-height: 10rem;
    font-family: 'Righteous', cursive;
    color: red;
    margin: 0;
    margin-bottom: 3rem;

}





.Tees {
    font-weight: 900;
    font-size: 13rem;
    letter-spacing: -6px;
    width: 100%;
    line-height: 10rem;
    font-family: 'Righteous', cursive;
    color: red;
    margin: 0;
    margin-bottom: 2rem;
    overflow-y: hidden;


}




.support--content {
    position: relative;
    /* top: 10%; */
    left: 30px;
    font-size: 2rem;


}

.support--content p {
    font-family: 'Crafty Girls', cursive;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
}

.start-creating {

    background-color: #dd5c18;
    border: none;
    color: white;
    font-weight: bolder;
    pointer-events: all;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    box-shadow: inset 0 0 0 0.09px black;

    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    border-radius: 50px;
}

.start-creating:hover {
    box-shadow: inset 250px 0 0 0 rgb(230, 59, 59);
    transform: scale(1.01);
}

/* CUSTOMIZER */

.customizer {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 25px;
    height: 95vh;
}

.customizer span {
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
}

.customizer span svg {
    width: 24px;
    padding-right: 5px;
}

.share {
    bottom: 40px;
    position: absolute;
    right: 40px;
}

.shop {
    bottom: 20px;
    position: absolute;
    right: 40px;
    font-size: 4rem;
    color: #726DE8;
    cursor: pointer;
}

.zoom {
    position: absolute;
    right: 20px;
    top: 50%;
    color: #00000056;
    font-size: 0.6rem;
    border: 1px solid #00000000;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    transition: border, color 1s ease;
}

.zoom:hover {
    color: black;
}

/* PICKER */

.picker {
    position: absolute !important;
    bottom: 4em;
    left: 50%;
    transform: translateX(-50%);
    width: 120px !important;
    height: 120px !important;
}

.color-options {
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
}

.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid white;
    transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.decals {
    position: absolute;
    left: 50px;
    bottom: 40px;

}



.upload {
    margin-top: -5px;
    cursor: pointer;
}

.upload:hover {
    color: white;
}


.exit {
    position: absolute;
    top: 5px;
    left: 0px;
    background-color: unset !important;
    box-shadow: unset;
    color: black;
    font-size: 1rem;
    background-color: #dd5c18;
    border: none;
    border-radius: 4px;
    font-weight: bolder;
    pointer-events: all;
    cursor: pointer;
    transition: all 0.4s ease-in-out;


    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 30px;
}




.emoji-picker-div {
    --background-rgb: 85, 170, 255;
    --border-radius: 24px;
    --category-icon-size: 24px;
    --color-border-over: rgba(0, 0, 0, 0.1);
    --color-border: rgba(0, 0, 0, 0.05);
    --font-family: 'Comic Sans MS', 'Chalkboard SE', cursive;
    --font-size: 20px;
    --rgb-accent: 255, 105, 180;
    --rgb-background: 262, 240, 283;
    --rgb-color: 102, 51, 153;
    --rgb-input: 255, 235, 235;
    --shadow: 5px 5px 15px -8px rebeccapurple;

    height: 37vh;
    min-height: 400px;
    max-height: 800px;

    width: 360px;
    min-width: 250px;
    resize: horizontal;
    overflow: auto;


}



.adddecalsbtn {
    margin-top: 1rem;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    margin-right: 0.7rem;


}



.emoji-in-heading {
    font-size: 11rem;
}


.call-us {
    position: absolute !important;
    bottom: 0 !important;
    font-family: 'Montserrat', sans-serif !important;
    transform: translate(-50%, -50%);
    left: 70%;



}


/* modal */

.modal-style {
    position: absolute !important;
    top: 10% !;
    left: 10%;

    height: 100%;
    display: block;
}

.modal-box {

    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    overflow: scroll;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 50%;
    max-height: 90%;



}

.modal-box-text {

    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    justify-content: center;
    overflow: scroll;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    width: 50%;
    max-height: 90%;



}

.text-modal {

    padding: 1rem;
    width: 30% !important;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    width: 50%;
    max-height: 90%;
}



.Paper-for-Scroll {
    overflow: auto;
    /* height: 100vh; */



}

.modal-topography {
    /* font-family: 'Montserrat', sans-serif !important; */



    /* margin-top: 5rem; */

}

.modal-created-image {
    width: 100%;
    border-radius: 10px;


}

.modal-created-image-ecom {
    width: 50%;
}

.price {
    position: absolute;
    font-size: 1.5rem;
    top: 1rem;
    left: 1rem;
    font-family: 'Montserrat', sans-serif !important;
    border-radius: 10px;
    padding: 0.3rem;
    color: white;
}

.cancel-modal {
    position: absolute;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif !important;
    border-radius: 10px;
    padding: 0.3rem;
    color: white;
    transform: translate(-50%, -50%);
    top: 5%;
    right: 0%;
    font-size: 2rem;
}


.text-fields {
    padding: 1rem;
    margin: 0.3rem !important;
    margin-left: 0rem !important;
    background-color: white;
    border-radius: 10px;

}

.text-fields-input {
    padding: 1.5rem !important;
    margin: 0.3rem !important;
    margin-left: 0rem !important;
    border-radius: 10px;

}

.order-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin: 1.5rem 5rem;
    transition: 0.5s;

}

.order-div:hover {
    scale: 1.1;

}

.order-btn {
    color: white !important;

}

.order-btn:hover {
    background-color: transparent !important;

}

.order-btn:active {
    background-color: transparent !important;

}

/* #demo-simple-select-standard,
#filled-basic,
.menu-items {} */

.size-quantity-div {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.image-div {

    display: flex;
    justify-content: center;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;
}

.isloadingDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    height: 180px;
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 20px;
    border: 5px solid white;
    z-index: 9999;
    font-family: 'Montserrat', sans-serif !important;
}

.isConfirmedDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 20px;
    border: 5px solid white;
    z-index: 9999;
    font-family: 'Montserrat', sans-serif !important;
    overflow: auto;
    min-height: 80rem;


}


.user-order-info {
    font-weight: bold;
}

.fun-tip {
    color: white;
    text-align: center;
    font-weight: bold;
}

table {
    border-collapse: collapse;

}

th,
td {
    border: 1px solid black;
    padding: 8px;

}

.selectedColorOnOrder {
    height: 15px;
    width: 15px;
    position: absolute;
    border-radius: 50px;
    top: 12px;
    margin-left: 0.2rem;

}

.selectedEmojiOnOrder {
    height: 25px;
    width: 25px;
    margin-left: 0.2rem;
}

em-emoji-picker {
    height: 400px !important;

}

.fun-tip {
    background-color: #704c4c;
    padding: 0.5rem;
    border-radius: 20px;
}

.MuiInput-underline {
    /* border-bottom: 2px solid red !important; */
    /* Change this to your desired color */
}


.logo {
    width: 7rem;
}


.inspirationImages {
    width: 100%;
    /* height: 50%; */
    margin: 0.5rem;
    border-radius: 5px;
}

.InpsirationModalText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Lexend', sans-serif !important;
    font-size: 2rem;
}



/* in house artwork */

.inhouseartdiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: 'center';
}

@media screen and (max-width: 768px) {

    .modal-topography {
        font-family: 'Montserrat', sans-serif !important;
        display: flex;
        flex-direction: column;


    }

    /* .inspirationImages {
        width: 100%;
        margin: 1rem;
        border-radius: 20px;
    } */
}



@media screen and (max-width: 600px) {

    .section--container {
        margin-left: 15vw;
    }

    .section--container h1 {
        font-size: 5rem;
        letter-spacing: -6px;
        line-height: 6rem;
        margin-bottom: 0rem;

    }

    .Tees {
        font-size: 5rem;
        letter-spacing: -6px;
        line-height: 6rem;
        overflow-y: hidden;


    }



    .color-options {

        margin-bottom: 20px;
        flex-direction: column;
        position: absolute;
        top: 50%;
        /* right: 40px; */
        transform: translateY(-50%);
        z-index: 100;
        left: 0.8rem;
    }

    .support--content {
        top: 0%;
        left: 0rem;
        font-size: 1.5rem;
    }

    .section-main {

        left: -2rem;

    }

    em-emoji-picker {
        position: absolute;
        width: 300px !important;
        height: 350px !important;
        bottom: 5rem !important;
        left: 0rem !important;
        z-index: 9999999 !important;


    }


    .exit {
        position: absolute;
        top: 5px;
        left: 0px;
        background-color: unset !important;
        box-shadow: unset;
        color: black;
        font-size: 1rem;
    }



    .adddecalsbtn {
        margin-top: unset;
        width: 3rem;

    }

    .show-emoji-text-btn {
        width: 2.5rem;
    }

    .shopping-cart {
        width: 2.5rem !important;
    }

    .emoji-in-heading {
        font-size: unset;

    }

    .emojimain {
        width: 5rem !important;
        margin-left: 1rem;
    }

    .shop {

        font-size: 2rem;
        margin-bottom: 20px;
    }


    /* modals */

    .modal-box {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: unset;
        transform: unset;
        width: 100%;
        max-height: unset;
        /* max-height: 90%; */



    }

    .text-modal {
        width: 80% !important;
    }

    .image-div {

        display: flex;
        justify-content: center;
        border-bottom-left-radius: 200px;
        border-bottom-right-radius: 200px;

    }


    .modal-created-image {

        width: 50%;
        margin: 2rem 2rem 0rem 2rem;


    }

    .modal-topography {

        margin-top: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;



    }

    .price {
        position: absolute;
        font-size: 1.5rem;
        font-family: 'Montserrat', sans-serif !important;
        border-radius: 10px;
        padding: 0.3rem;
        color: white;
        transform: translate(-50%, -50%);
        top: 10%;
        left: 22%;
    }




    .size-quantity-div {
        margin-top: unset;
    }

    .order-btn {
        color: white !important;
        font-size: 1.3rem !important;
    }


    #demo-simple-select-standard,
    #filled-basic,
    .menu-items {
        font-size: 1rem !important;


    }

    .text-fields {}

    .cancel-modal {
        right: 2%;
    }

    .call-us {

        left: 90%;
        font-size: 0.7rem;
        width: 100%;

    }

    table {
        font-size: 0.7rem;


    }

    .table-div {
        display: flex;
        justify-content: center;
    }

    .selectedColorOnOrder {
        height: 13px;
        width: 13px;

        top: unset;

    }



    .logo {
        width: 3.3rem;
    }


    .InpsirationModalText {

        font-size: 1rem;
    }




}

@media screen and (max-width: 330px) {
    em-emoji-picker {
        position: absolute;
        width: 200px !important;
        height: 250px !important;
        bottom: 50px !important;
        left: 0px !important;


    }

    .text-fields-div {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }


}